import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    document: Document;
    constructor(@Inject(DOCUMENT) private _document: Document) {
        if(window.localStorage){ // SSR check
            this.document = this._document;
        }else {
            this.document = { ...this._document, cookie: '' };
        }
    }

    public setCookie(name: string, value: string, expiryDays = 30) {
        let cookie = name + "=" + value + ";";

        if (expiryDays) {
            const d = new Date();
            d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + d.toUTCString();
            cookie = cookie + expires + ";path=/";
        }
        this.document.cookie = cookie;
    }

    public getCookie(name: string) {
        const ca: string[] = this.document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;
        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return null;
    }

    public deleteCookie(name:string) {
        this.setCookie(name, '', -1);
    }

}