import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';
import { IJWTUser, IResponseJson } from '../../shared/models/interfaces';
import { Router } from '@angular/router';
import { CookieService } from './cookie.service';
import { LOGIN_WITH } from '../../layouts/auth/auth.layout';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BaseService {

    private apiUrl = environment.apiUrl + '/auth';
    currentUser: BehaviorSubject<IJWTUser | null> = new BehaviorSubject<IJWTUser | null>(null);

    constructor(
        private router: Router,
        protected override http: HttpClient,
        private cookieService: CookieService
    ) {
        super(http);
        
        const token = this.cookieService.getCookie('token');
        if(token) {
            const user = jwtDecode(token);
            this.currentUser.next(user as IJWTUser);
        }
    }

    multipleCustomersCheck(contactNumber: string) {
        return this.get<IResponseJson<boolean>, { contactNumber: string }>(this.apiUrl + '/multiple-customers-exist', { contactNumber });
    }
    
    sendOtp(body: { customerID?:string, contactNumber?: string, pan? : string }) {
        return this.post<IResponseJson<{ contactNumber: string, email? : string }>, { customerID?:string, contactNumber?: string, pan? : string }>(this.apiUrl + '/otp/send', body);
    }
    
    verifyOtp(body: { customerID?:string,  contactNumber?: string , otp: string }) {
        return this.post<IResponseJson<string>, { customerID?:string,  contactNumber?: string , otp: string }>(this.apiUrl + '/otp/verify', body);
    }

    loginUser() {
        const token = this.cookieService.getCookie('token');
        if(token) {
            const user = jwtDecode(token);
            this.currentUser.next(user as IJWTUser);
            this.router.navigate(['/']);
        }
    }

    logOutUser(state?: { loginWith: LOGIN_WITH }) {
        this.cookieService.deleteCookie('token');
        this.currentUser.next(null);
        this.router.navigate(['/login'], { state });
    }
}